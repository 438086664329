.tui-image-editor-canvas-container {
  width: 100% !important;
  height: 100%  !important;
  max-width: 100% !important;
  max-height: 100% !important;
  border-radius: 1rem;
}
.tui-image-editor-canvas-container canvas {
  width: 100% !important;
  height: 100%  !important;
  max-width: 100% !important;
  max-height: 100% !important;
  border-radius: inherit;
}

.editor-container.full-editor {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.backdrop {
  background-color: #00000010;
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 21;
  inset: 0;
}