.tui-image-editor-canvas-container {
	/* max-width: 100% !important;
  max-height: 100%  !important;
  border-radius: 1rem; */
}
.tui-image-editor-canvas-container canvas {
  /* max-width: 100% !important;
  max-height: 100%  !important; */
  border-radius: inherit;
}
