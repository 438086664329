@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Montserrat:ital,wght@0,700;0,900;1,700;1,900&family=Open+Sans:wght@500;800&family=Oswald:wght@400;700&family=Pacifico&family=Permanent+Marker&family=Raleway:ital,wght@0,500;0,900;1,500;1,900&family=Ultra&display=swap');

@keyframes bounce {
  35%,
  65% {
    scale: var(--scale);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes drainCoins {
  0% {
    y: 0;
  }

  100% {
    y: 200px;
  }
}

.no-credit-icon-animation .filled {
  animation-name: drainCoins;
  animation-duration: 0.7s;
  animation-timing-function: ease-out;
  transition: all 0.7s;
  height: 210px;
}

.title-gradient {
  background: rgb(255, 209, 88);
  background: linear-gradient(
    90deg,
    rgba(255, 209, 88, 1) 0%,
    rgba(226, 246, 101, 1) 12%,
    rgba(123, 254, 147, 1) 31%,
    rgba(72, 240, 183, 1) 43%,
    rgba(40, 175, 222, 1) 72%,
    rgba(10, 126, 253, 1) 88%,
    rgba(0, 112, 244, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ReactCrop__child-wrapper {
  max-height: 80vh;
  max-width: 80vhw;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}

:root {
  --transition: 0.25s;
  --spark: 1.8s;
}

#root {
  @apply h-screen;
}

body {
  --active: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sparkle path {
  color: hsl(0 0% calc((var(--active, 0) * 70%) + var(--base)));
  transform-box: fill-box;
  transform-origin: center;
  fill: currentColor;
  stroke: currentColor;
  animation-delay: calc((var(--transition) * 1.5) + (var(--delay) * 1s));
  animation-duration: 0.6s;
  transition: color var(--transition);
}

.sparkle path:nth-of-type(1) {
  --scale: 0.5;
  --delay: 0.1;
  --base: 100%;
}

.sparkle path:nth-of-type(2) {
  --scale: 1.5;
  --delay: 0.2;
  --base: 100%;
}

.sparkle path:nth-of-type(3) {
  --scale: 2.5;
  --delay: 0.35;
  --base: 100%;
}

button.animation-running path {
  --active: 1;
  --play-state: running;
  animation: 1s ease-in-out 0.6s 1 reverse both bounce;
}

button svg {
  inline-size: 1.25em;
}

#generate-button span {
  transition: all 0.2s ease-in-out;
}

#generate-button.starting-to-load #button-ready {
  top: 150%;
}

#generate-button.loading #button-ready {
  top: 150%;
}

#generate-button #button-ready {
  opacity: 0;
}

#generate-button.ready #button-ready {
  opacity: 100%;
}

#generate-button #button-loading {
  opacity: 0;
}

#generate-button #button-success {
  opacity: 0;
}

#generate-button #button-fail {
  opacity: 0;
}

#generate-button.loading #button-loading {
  opacity: 100%;
}

#generate-button.loading #button-ready {
  opacity: 0;
}

#generate-button.starting-success #button-loading {
  top: 150%;
}

#generate-button.success #button-loading {
  top: 150%;
}

#generate-button.success #button-success {
  opacity: 100%;
}

#generate-button.starting-success #button-success {
  opacity: 100%;
}

#generate-button.ending-success #button-success {
  top: 150%;
}

#generate-button.ending-success #button-ready {
  opacity: 100%;
}

#generate-button.fail #button-fail {
  opacity: 100%;
}

#generate-button.starting-fail #button-fail {
  opacity: 100%;
}

#generate-button.ending-fail #button-fail {
  top: 150%;
}

#generate-button.ending-fail #button-ready {
  opacity: 100%;
}

.tippy-tooltip.dark-theme {
  border: 1px solid #292932 !important;
  background: #1c1c24 !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5em;
  max-width: 300px;
}

.tippy-tooltip-content {
  font-family: inherit;
}

.loader {
  width: 48px;
  height: 48px;
  position: relative;
  background: #373741;
  border-radius: 4px;
  overflow: hidden;
}
.v4loader {
  width: 30px;
  height: 30px;
  position: relative;
  background: #373741;
  border-radius: 4px;
  overflow: hidden;
}

.loader:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  transform: rotate(45deg) translate(30%, 40%);
  background: #8a8a92;
  box-shadow: 32px -34px 0 5px #5b5b67;
  animation: slide 2s infinite ease-in-out alternate;
}
.v4loader:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  transform: rotate(45deg) translate(30%, 40%);
  background: #8a8a92;
  box-shadow: 20px -8px 0 2px #5b5b67;
  animation: slide 2s infinite ease-in-out alternate;
}

.loader:after {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3dd598;
  transform: rotate(0deg);
  transform-origin: 35px 145px;
  animation: rotate 2s infinite ease-in-out;
}
.v4loader:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3dd598;
  transform: rotate(0deg);
  transform-origin: 35px 145px;
  animation: rotate 2s infinite ease-in-out;
}

.custom-overlay-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(41, 41, 50, 0) 60%,
    rgba(41, 41, 50, 0.75) 100%
  );
}

.custom-overlay-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(41, 41, 50, 0) 60%,
    rgba(41, 41, 50, 0.75) 100%
  );
}

@keyframes slide {
  0%,
  100% {
    bottom: -35px;
  }

  25%,
  75% {
    bottom: -2px;
  }

  20%,
  80% {
    bottom: 2px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-15deg);
  }

  25%,
  75% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(25deg);
  }
}

.custom-scroll {
  scrollbar-gutter: stable;
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.custom-scroll-padding {
  padding-left: 7px;
}

@media (min-width: 640px) {
  .custom-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .custom-scroll-padding {
    padding-left: 5px;
  }
}

@media (min-width: 1024px) {
  .custom-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .custom-scroll-padding {
    padding-left: 8px;
  }
}

@media (min-width: 1280px) {
  .custom-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .custom-scroll-padding {
    padding-left: 10px;
  }
}

/* Scrollbar */
.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: #292932;
  cursor: default;
}

.v4-custom-scroll::-webkit-scrollbar-thumb {
  background: #1c1c24;
}

.v4-custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.prompt-custom-scroll::-webkit-scrollbar-thumb {
  background: #3a3a4c;
}

.prompt-custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.txt2img-custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.browse-gallery-custom-scroll::-webkit-scrollbar-thumb {
  background: #3a3a4c;
}

/* Element Highlighter */

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}
.highlighter-values {
  --d: 2700ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;

  --col3: #4eeced;
  --col2: #d0ff79;
  --col1: #5060e4;
}
.highlighter {
  border-image: conic-gradient(
      from var(--angle),
      var(--col1),
      var(--col2) 0.1turn,
      var(--col3) 0.13turn,
      var(--col3) 0.16turn,
      var(--col2) 0.19turn,
      var(--col1) 0.22turn,
      var(--col1) 0.5turn,
      var(--col2) 0.6turn,
      var(--col3) 0.63turn,
      var(--col3) 0.66turn,
      var(--col2) 0.69turn,
      var(--col1) 0.72turn
    )
    1;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 450deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

@keyframes buttonBgAnimation {
  0% {
		transform: translate(0, 0);
  }
  100% {
		transform: translate(100%, 0);
  }
}

.button-loading-bg {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: buttonBgAnimation;
  animation-timing-function: linear;
}


/* Driver */
.driver-popover.driverjs-theme {
  background-color: #13131A;
  color: rgba(255, 255, 255, .60);
	border-radius: 0.5rem;
}

.driver-popover.driverjs-theme .driver-media {
	margin-top: -15px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 5px;
	width: calc(100% + 30px);
	max-width: unset;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	aspect-ratio: 16 / 9;
}

.driver-popover.driverjs-theme .driver-popover-title {
	color: #fff;
	font-size: 1.25rem;
}

.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-description {
  color: rgba(255, 255, 255, .60);
	font-size: 1rem;
}

.driver-popover.driverjs-theme .driver-popover-description a {
  color: #8058F3;
	font-size: 0.75rem;
	text-decoration: underline;
}

.driver-popover.driverjs-theme .driver-popover-progress-text {
	display: flex !important;
	gap: 0.25rem;
}

.driver-popover.driverjs-theme .driver-popover-progress-text .driver-step {
	border-radius: 999px;
	background-color: #2A2A36;
	width: 1rem;
	height: 1rem;
	border: 0px;
	outline: 0px !important;
}

.driver-popover.driverjs-theme .driver-popover-progress-text .driver-step:hover {
	opacity: 0.8;
}

.driver-popover.driverjs-theme .driver-popover-progress-text .driver-step.active {
	background-color: #8058F3;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
	flex-grow: 0;
	gap: 0.25rem;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns .text {
	display: flex;
	gap: 0.25rem;
	align-items: center;
}

.driver-popover.driverjs-theme .driver-popover-next-btn {
	--tw-bg-opacity: 1;

  text-align: center;
  color: #fff;
	font-family: Inter, sans-serif;
  font-size: 14px;
	padding: 0.5rem 0.8rem;
	border-radius: 0.5rem;
	border: 0px;

	background-color: rgb(128 88 243/var(--tw-bg-opacity));
}
.driver-popover.driverjs-theme .driver-popover-next-btn:hover {
	--tw-bg-opacity: 0.8;
}

.driver-popover.driverjs-theme .driver-popover-prev-btn {
	--tw-bg-opacity: 1;

  text-align: center;
  color: rgba(255, 255, 255, 0.6);
	font-family: Inter, sans-serif;
  font-size: 14px;
	padding: 0.5rem 0.8rem;
	border-radius: 0.5rem;
	border: 0px;

	background-color: rgb(41 41 50/var(--tw-bg-opacity));
}
.driver-popover.driverjs-theme .driver-popover-prev-btn:hover {
	--tw-bg-opacity: 0.8;
}
.driver-popover-footer button {
	text-shadow: none;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #13131A;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #13131A;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #13131A;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #13131A;
}



#shopper_approved #sa_outer {
	margin: auto auto !important;
	max-width: 480px !important;
	padding: 0 !important;
	top: 50% !important;
	left: 50% !important;
	position: absolute !important;
	transform: translate(-50%, -50%) !important;
}
#shopper_approved #sa_header_img {
	width: 130px !important;
	max-width: 150px !important;
}
#shopper_approved #sa_header_text {
	display: block !important;
}

#shopper_approved {
	z-index: 32767;
	font: 14px arial,sans-serif !important;
	color: black !important;
	display: none;
	position: absolute;
	width: 100%;
	height: 100% !important;
}

